import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query Navbar {
    logo: file(relativePath: { eq: "dont-kill-them-all-vectorial.svg" }) {
      publicURL
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Dkta.Queries.NavbarQuery>>(query)
