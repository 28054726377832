import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query PlatformLinks {
    site {
      siteMetadata {
        externalUrls {
          steam
        }
      }
    }
  }
`

export default () =>
  useStaticQuery<RecursiveNonNullable<Dkta.Queries.PlatformLinksQuery>>(query)
