import { type TPlatform } from '@shared/ui'
import { useMemo } from 'react'

import useData from './query'

export default () => {
  const {
    site: {
      siteMetadata: {
        externalUrls: { steam: steamUrl },
      },
    },
  } = useData()

  const links = useMemo<{ ariaLabel: TPlatform; to: string }[]>(
    () => [{ ariaLabel: 'Steam', to: steamUrl }],
    [steamUrl]
  )

  return links
}
