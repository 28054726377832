import 'dkta/global-styles.css'

import { type TPageContext, PageLayout as SharedPageLayout } from '@shared/ui'
import React, { FC, ReactNode } from 'react'

import TRANSLATIONS from 'dkta/translations'

import LegalSection from './LegalSection'
import Navbar from './Navbar'

interface Props {
  children: ReactNode
  pageContext: TPageContext
}

const Layout: FC<Props> = ({ children, pageContext }) => (
  <SharedPageLayout pageContext={pageContext} translations={TRANSLATIONS}>
    <Navbar />
    {children}
    <LegalSection />
  </SharedPageLayout>
)

export default Layout
