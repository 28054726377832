import {
  ExternalLinkType,
  LanguageSelector,
  Link,
  Navbar as SharedNavbar,
  NAVBAR_ANIMATED_ELEMENT_STYLES,
  NavbarState,
  PageContext,
  PageLinks,
  Platforms,
  SocialMedia,
} from '@shared/ui'
import classNames from 'classnames'
import React, { FC, ReactNode, useCallback, useContext } from 'react'
import { ReactSVG } from 'react-svg'

import usePlatformLinks from 'dkta/hooks/PlatformLinks'
import useSocialMediaLinks from 'dkta/hooks/SocialMediaLinks'
import Pages, { getTheme, NAVBAR_LINKS } from 'dkta/pages'

import useData from './query'
import useState from './state'
import styles from './styles.module.css'

const Navbar: FC = () => {
  const { slug } = useContext(PageContext)
  const platformLinks = usePlatformLinks()
  const socialMediaLinks = useSocialMediaLinks()
  const {
    getHomeLinkWidth,
    getLanguageSelectorWidth,
    getPageLinksWidth,
    getPlatformLinksWidth,
    getSocialMediaLinksWidth,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    pageLinkRef,
    platformLinkIconRef,
    platformLinkRef,
    socialMediaLinkIconRef,
    socialMediaLinkRef,
  } = useState(platformLinks.length, socialMediaLinks.length)

  const {
    logo: { publicURL: logo },
  } = useData()

  return (
    <SharedNavbar
      getElementsWidth={useCallback(
        () =>
          getHomeLinkWidth() +
          getPlatformLinksWidth() +
          getSocialMediaLinksWidth() +
          getPageLinksWidth() +
          getLanguageSelectorWidth(),
        [
          getHomeLinkWidth,
          getPlatformLinksWidth,
          getSocialMediaLinksWidth,
          getPageLinksWidth,
          getLanguageSelectorWidth,
        ]
      )}
      getTheme={getTheme}
      left={useCallback<(state: NavbarState, burgerized: boolean) => ReactNode>(
        () =>
          slug !== Pages.HOME && (
            <Link
              ref={homeLinkRef}
              className={classNames(styles.link, styles.homeLink)}
              to="/"
            >
              <ReactSVG
                afterInjection={homeLinkImageRef}
                className={styles.homeLinkImage}
                src={logo}
              />
            </Link>
          ),
        [homeLinkImageRef, homeLinkRef, logo, slug]
      )}
      right={useCallback<
        (state: NavbarState, burgerized: boolean) => ReactNode
      >(
        (state, burgerized) => (
          <>
            {burgerized ? (
              <div className={styles.platformAndSocialMediaLinks}>
                <Platforms
                  focusable={state === NavbarState.FULLSCREEN}
                  links={platformLinks}
                  type={ExternalLinkType.FABS}
                />

                <SocialMedia
                  focusable={state === NavbarState.FULLSCREEN}
                  links={socialMediaLinks}
                  type={ExternalLinkType.FABS}
                />
              </div>
            ) : (
              <>
                <Platforms
                  ref={platformLinkRef}
                  className={classNames(
                    styles.link,
                    styles.platformAndSocialMediaLink,
                    NAVBAR_ANIMATED_ELEMENT_STYLES
                  )}
                  imageRef={platformLinkIconRef}
                  links={platformLinks}
                  type={ExternalLinkType.ICONS}
                />

                <SocialMedia
                  ref={socialMediaLinkRef}
                  className={classNames(
                    styles.link,
                    styles.platformAndSocialMediaLink,
                    NAVBAR_ANIMATED_ELEMENT_STYLES
                  )}
                  imageRef={socialMediaLinkIconRef}
                  links={socialMediaLinks}
                  type={ExternalLinkType.ICONS}
                />
              </>
            )}

            <PageLinks
              ref={burgerized ? undefined : pageLinkRef}
              className={classNames(
                styles.link,
                NAVBAR_ANIMATED_ELEMENT_STYLES
              )}
              focusable={
                burgerized ? state === NavbarState.FULLSCREEN : undefined
              }
              links={NAVBAR_LINKS}
            />

            <LanguageSelector
              ref={burgerized ? undefined : languageSelectorRef}
              className={styles.languageSelector}
              containerClassName={classNames(
                styles.link,
                NAVBAR_ANIMATED_ELEMENT_STYLES
              )}
              focusable={
                burgerized ? state === NavbarState.FULLSCREEN : undefined
              }
            />
          </>
        ),
        [
          languageSelectorRef,
          pageLinkRef,
          platformLinkIconRef,
          platformLinkRef,
          platformLinks,
          socialMediaLinkIconRef,
          socialMediaLinkRef,
          socialMediaLinks,
        ]
      )}
    />
  )
}

export default Navbar
